<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="lg"
      color="secondary"
      addContentClasses="modal-max-width"
    >
        <List ref="list" 
          showSearch
          :comboboxData=comboboxData
          :defaultSearchType=searchType
          :fields=fields
          :items.sync=appInfoList
          :loading.sync=loading
          sorter      
          :defaultSort=defaultSort
          itemsPerPageSelect
          :itemsPerPage=itemPerPage
          :mustCheckedList=appCheckedList
          :isModal=isModal
        >    
        	
          <template #no="{index}">
            <td class="py-2" >
              {{appInfoTotalCnt - ((pageNum -1) * itemPerPage +index)}}
            </td>
          </template>
        </List>
        <page-link ref="page"
        :pageLinkCount=pagePerLink
        :listRowCount=itemPerPage
        />
      <template #header>
        <h6 class="modal-title">앱 제어권한 추가</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
      </template>
      <template #footer>
        <CButton color="secondary" @click="$emit('close-modal')">취소</CButton>
        <CButton color="success" @click="addAppPermissionItem()">추가</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
import List from '@/views/common/List'
import PageLink from '@/views/common/PageLink'
export default {
  name: 'AppPermissionModal',
  components: {
    List,
    PageLink
  },
  props: {
    caption: {
      default: '앱제어관리',
    },
    chkAppKeyList: Array
  },
  data () {
    return {
        isModalOpen:true,
        searchType:'appNm',
        searchText:'',
        comboboxData:[{label: '앱 이름', value: 'appNm'},{label: '패키지명', value:'packageNm'}],
        fields:[
            { key: 'check_box', label:'', _style: { width: '1%'}, mapping:'appKey'},
            { key: 'no', label:'No', _style: { width: '8%'} },
            { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
            { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
            { key: 'appVersion', label:'버전', sorter: false, _style: { width: '20%'} },
            { key: 'osType', label: 'OS', sorter: false, _style: { width: '20%'}, iconYn: true },
            { key: 'storeYn', label: '스토어', _style: {width: '8%'}, codeYn: true}
        ],
        pagePerLink: 5,
        defaultSort: '-appNm',
        pageNum: 1,
        itemPerPage:5,
        loading:false, 
        appCheckedList:this.chkAppKeyList,
        isModal:true
    }
  },
  computed: {
    appInfoList(){
      return this.$store.state.appInfo.appInfoList || []
    },
    appInfoTotalCnt(){
      return this.$store.state.appInfo.appInfoListTotalCnt
    }
  },
  mounted(){
    this.searchList()
  },
  methods: {
    // //리스트 조회 
    async searchList(){
        let payload = this.$refs.list.getParams()
        this.loading=true
        try {
          await this.$store.dispatch('appInfo/getAppInfoList', payload)
          this.$refs.page.reloadPagination(this.$store.state.appInfo.appInfoListTotalCnt)
          this.loading=false
        } catch(error){
          console.log(error)
          this.loading=false
        }
    },
    addAppPermissionItem(){
       let appPermissionInfoList = []
       let checkedList = this.$refs.list.getCheckedList()
       if(checkedList.length == 0){
         alert('앱을 선택해주세요.')
         return false
       }
        for(var i in this.appInfoList){
          if(checkedList.includes(this.appInfoList[i]['appKey']))  
            appPermissionInfoList.push(this.appInfoList[i])
        }
      this.$emit('add-item-event', appPermissionInfoList)
    }
  }
}
</script>
