<template>
  <div>
    <CRow>
        <CCol lg="12" style="margin-bottom:10px">
            <CButton type="submit" size="sm" class="float-left btn-primary" @click="$router.go(-1)" ><CIcon name="cil-list"/> 목록</CButton>
            <CButton v-if="permissionGroupInfo.groupType=='1'&&!isModifyView && $hasWritePermission('302')" type="submit" size="sm" color="primary" class="float-right" @click="fnModifyAuthGroup" ><CIcon name="cil-pencil"/> 수정</CButton>
            <CButton v-if="isModifyView" type="submit" size="sm" color="primary" class="float-right btn-lft-margin" @click="_validation()"><CIcon name="cil-check-circle"/>저장</CButton>
            <CButton v-if="isModifyView" type="submit" size="sm" color="danger" class="float-right" @click="fnCancelAuthGroupInfo" ><CIcon name="cil-x-circle"/> 취소</CButton>
            
        </CCol>
    </CRow>

    <CRow>
        <CCol lg="12">
         <CCard>
             <CCardBody>
                 <CForm>
                    <CInput
                        label="권한 이름"
                        horizontal
                        autocomplete="name"
                        :disabled="!isModifyView"
                        v-model="permissionGroupInfo.groupNm"
                    />
                    <CInput
                        label="비고"
                        horizontal
                        autocomplete="name"
                        :disabled="!isModifyView"
                        v-model="permissionGroupInfo.groupDesc"
                    />
                 </CForm>
             </CCardBody>
         </CCard>
        </CCol>
    </CRow>
    <CRow>
        <AppPermissionModal v-if="isOpenAppPermissionModal" @close-modal="isOpenAppPermissionModal=false" @add-item-event="addAppPermissionItem" :chkAppKeyList="appKeyList"/>
        <CCol lg="12">
            <CCard>
                <CCardHeader>
                    <strong> 앱 제어 권한 </strong>
                    <CButton
                        type="submit"
                        size="sm"
                        variant="ghost"
                        class="float-right btn-primary btn-lft-margin"
                        @click="isOpenAppPermissionModal=true"
                        :disabled="(isModifyView == false || allAppChecked == true)"
                    >
                        <CIcon name="cil-plus"/>
                    </CButton>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12" style="margin-bottom:10px">
                            <CInputCheckbox
                                    label="모든 앱"
                                    value="all"
                                    class="float-right"
                                    :disabled="!isModifyView"
                                    :checked="allAppChecked"
                                    @change="checkedAllApp($event.target.checked)"
                                    :custom="true"
                            />
                        </CCol>
                    </CRow>
                    <List ref="list"
                        :items.sync=appPermissionItems
                        :fields=appFields
                        :itemsPerPage=20
                    >
                       <template #no="{index}">
                            <td class="py-2" >
                                 {{appPermissionItems.length - index}}
                            </td>
                        </template>
                        <template #buttons="{item}" >
                            <td class="py-2" >
                                <CButton 
                                    type="submit"
                                    size="sm"
                                    variant="ghost"
                                    class="btn-primary"
                                    @click="removeAppPermissionItem(item.appKey)" 
                                    v-if="isModifyView">
                                    <CIcon name="cil-minus"/>
                                </CButton>
                            </td>
                        </template>  
                    </List> 
                </CCardBody>
            </CCard>
            
            <CCard>
                <CCardHeader>
                    <strong> 메뉴 접근 권한 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12" style="margin-bottom:10px">
                            <CInputCheckbox
                                    label="모든 권한"
                                    value="all"
                                    class="float-right"
                                    :disabled="!isModifyView"
                                    :checked="allMenuChecked"
                                    @change="checkedAllMenu($event.target.checked)"
                                    :custom="true"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="4" v-for="(item, index) in menuInfo" :key="index">
                            <CRow form class="form-group" >
                                <CCol tag="label" sm="3" class="col-form-label font-weight-bold">
                                   {{item.menuNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group" v-for="(subItem, subIndex) in item.childrenItems" :key="subIndex">
                                <CCol sm="3" v-if="subItem.menuKey!=102">
                                   {{subItem.menuNm}}
                                </CCol>
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Read"
                                    :inline="true"
                                    :checked="subItem.readYn==1"
                                    value="readYn"
                                     @change="checkedMenu($event)"
                                     v-if="subItem.menuKey!=102"
                                    :disabled="!isModifyView"
                                    :custom="!isModifyView"
                                    />
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Write"
                                    :checked="subItem.writeYn==1"
                                    value="writeYn"
                                    @change="checkedMenu($event)"
                                    v-if="item.menuKey!=101"
                                    :disabled="!isModifyView"
                                    :custom="!isModifyView"
                                    />
                                
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
            <CCard>
            <CCardHeader>
                <Strong>할당된 계정</Strong>
            </CCardHeader>
            <CCardBody>
                <List ref="list"
                    :items.sync="permissionGroupInfo.assignedAdminInfoList"
                    :fields=accountFields
                    :itemsPerPage=20
                >
                    <template #no="{index}">
                        <td class="py-2" >
                            {{permissionGroupInfo.assignedAdminInfoList.length - index}}
                        </td>
                    </template>
                </List>   
            </CCardBody>
        </CCard>
        </CCol>
    </CRow>
  </div>
</template>
<script>
import List from '@/views/common/List'
import AppPermissionModal from './AppPermissionModal'
export default {
  name: 'PermissionGroupInfo',
  components:{
    List,
    AppPermissionModal    
  },
  data () {
    return {
        allAppChecked: false,
        allMenuChecked: false,
        groupKey: this.$route.params.num,
        isOpenAppPermissionModal: false,
        isModifyView: false,
         // 그리드 컬럼 설정
        appFields:[
            { key: 'no', label:'No', _style: { width: '1%'} },
            { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
            { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
            { key: 'appVersion', label:'버전',  _style: { width: '20%'} },
            { key: 'osType', label: 'OS', _style: { width: '20%'}, iconYn: true },
            { key: 'storeYn', label:'스토어', _style: {width: '8%'}, codeYn:true},
            { key: 'buttons',label:'', _style: { width: '20%'} }
        ],
        accountFields:[
            {key: 'no', label:'No', _style: {width: '1%'}},
            {key: 'adminId', label:'아이디', _style: {width: '1%'}},
            {key: 'adminNm', label:'이름', _style: {width: '1%'}},
            {key: 'email', label:'이메일', _style: {width: '1%'}},
            {key: 'phoneNumber', label:'연락처', _style: {width: '1%'}},

        ],
        allAppPermissionYn:'0',
        allMenuPermissionYn: '0',
        beforeAppPermissionGroupInfo:[],
        appPermissionItems: [],
        appKeyList: [],
    }
  }, 
  props: {
    },
  computed: {
      permissionGroupInfo(){
          return this.$store.state.permissionGroup.permissionGroupInfo || []
      },
      menuInfo(){
          return this.$store.state.permissionGroup.menuPermissionInfo || []
      }
  },
  mounted(){
    this.searchInfo()
  },
  methods: {
    async searchInfo(){
        try{
            let response = await this.$store.dispatch('permissionGroup/getPermissionGroupInfo', this.groupKey)
                if(response.status == 200){
                    for(var i in response.data['appPermissionInfoList']){
                        this.appKeyList.push(response.data['appPermissionInfoList'][i]['appKey'])
                    }
                    this.appPermissionItems = response.data['appPermissionInfoList']
                    if(response.data['allAppPermissionYn'] == '1')
                        this.allAppChecked = true
                    else this.allAppChecked = false
                    if(response.data['allMenuPermissionYn'] == '1')
                        this.allMenuChecked = true
                    else this.allMenuChecked = false
                }
        }catch(error){
            console.log(error)
        }

    },
    fnModifyAuthGroup(){
        this.isModifyView = !this.isModifyView
    },
    fnCancelAuthGroupInfo(){
        this.isModifyView = !this.isModifyView
        this.searchInfo()
        //this.appPermissionItems = this.permissionGroupInfo['appPermissionInfoList']
    },
    checkedAllApp(checked){
       let checkedVal = '0'
        checked ? checkedVal = '1' : '0'
        if(checked){
            this.appPermissionItems = []
        }
        this.allAppPermissionYn =checkedVal
        this.allAppChecked = checked
        this.appKeyList = []
    },
    checkedAllMenu(checked){
        this.allMenuPermissionYn = checked ? '1' : '0'
        this.allMenuChecked = checked
        this.$store.commit('permissionGroup/checkedAllMenu', checked)
    },
    checkedMenu(event){
        this.$store.commit('permissionGroup/checkedMenu', event.target)
        let allReadMenuCnt = 0;
        let allWriteMenuCnt = 0;
        let readCheckedCnt = 0;
        let writeCheckedCnt = 0;
        for(let i in this.menuInfo){
            for(let j in this.menuInfo[i]['childrenItems']){
                if(this.menuInfo[i]['childrenItems'][j].readYn)
                    allReadMenuCnt++
                if(this.menuInfo[i]['childrenItems'][j].writeYn)
                    allWriteMenuCnt++
                if(this.menuInfo[i]['childrenItems'][j].readYn == '1')
                   readCheckedCnt++ 
                if(this.menuInfo[i]['childrenItems'][j].writeYn == '1')
                   writeCheckedCnt++
            }
        }
        if((allReadMenuCnt+allWriteMenuCnt) == (readCheckedCnt+writeCheckedCnt)){
            this.allMenuChecked = true
            this.allMenuPermissionYn = '1'
        }
        else {
            this.allMenuChecked = false
            this.allMenuPermissionYn = '0'
        }
    },
    addAppPermissionItem(data){
        this.appPermissionItems = []
        this.appPermissionItems = data
        this.isOpenAppPermissionModal = false
    },
    removeAppPermissionItem(appKey){
         for(var i in this.appPermissionItems){
             if(this.appPermissionItems[i].appKey == appKey)
                 this.appPermissionItems.splice(i, 1)
         }
    },
    _validation(){
        if(this.permissionGroupInfo.groupNm == ''){
            alert('권한 그룹명을 입력해주세요.')
            return false
        }
        let chkReadPermissionCnt = 0
        for(let i in this.menuInfo){
            for(let j in this.menuInfo[i]['childrenItems']){
                if(this.menuInfo[i]['childrenItems'][j].readYn == '1')
                   chkReadPermissionCnt++ 
            }
        }
        if(chkReadPermissionCnt == 0){
            alert('메뉴권한의 읽기권한은 하나 이상 존재하여야 합니다.')
            return false
        }

        this.modify()
    },
    async modify(){
        let payload ={
            groupKey: this.groupKey,
            groupNm: this.permissionGroupInfo.groupNm,
            groupDesc: this.permissionGroupInfo.groupDesc, 
            allAppPermissionYn: this.allAppPermissionYn,
            allMenuPermissionYn : this.allMenuPermissionYn,
            menuPermissionInfoList: this.menuInfo,
            appPermissionInfoList : this.appPermissionItems
        }
        let confirmMsg = '권한을 수정하시겠습니까?'
        if(confirm(confirmMsg)){
            try{
                let response = await this.$store.dispatch('permissionGroup/modifyPermissionGroup', payload)
                if(response.status == 200){
                    alert('수정을 완료했습니다.')
                    this.searchInfo()
                    this.isModifyView = !this.isModifyView
                }
            }catch(error){
                this.$response(error)
            }
        }
    }
  }
}
</script>
